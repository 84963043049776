import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import logo from "../../../../assets/images/logo.png";
import x from "../../../../assets/images/twitter.png";
import tg from "../../../../assets/images/telegram.png";
import medium from "../../../../assets/images/medium.png";
import { MenuLink, MobileMainMenu,  DrawerBox, DrawerDivider, Humburgger, HumburggerClose, MenuButton } from "../styles";
import { DataImage, SectionHeading } from "../../../Styles/style";


const MobileMenu = (props) => {
  const [state, setState] = useState({ left: false });
  
  const toggleDrawer = (anchor, open) => (event) => {
    console.log('thest: ', anchor + " : " +open)
    if ( event.type === "keydown" && (event.key === "Tab" || event.key === "Shift") ){return;}
    setState({ ...state, [anchor]: open });
  };
  return (
    <MobileMainMenu>
      <MenuLink href="/" p="15px" sx={{display:"flex !important"}}>
        <DataImage src={logo} mw="40px" />
        <SectionHeading fs="18px">FLOKI GOLD</SectionHeading>
      </MenuLink>
      <MenuButton onClick={toggleDrawer("left", true)}>
        {state["left"] ? (
          <HumburggerClose>Close</HumburggerClose>
        ) : (
          <Humburgger></Humburgger>
        )}
      </MenuButton>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <DrawerBox
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <DrawerDivider>
            <MenuLink href="/">
            <DataImage src={logo} mw="40px" />
            <SectionHeading>FLOKI GOLD</SectionHeading>
            </MenuLink>
            <Divider style={{background:"#f0b706"}}/>
            <List>
              {props.menuList.map((value, i) => <MenuLink key={i} href={value.link}  target={value.target}>{value.title}</MenuLink>)}
            </List>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap:"5px"
              }}
            >
              <MenuLink href="https://twitter.com/Flokigold_erc"  target="blank">
                <DataImage mw="30px" src={x} />
              </MenuLink>
              <MenuLink   href="https://t.me/Flokigold_erc" target="blank">
                <DataImage mw="30px" src={tg}/>
              </MenuLink>
              <MenuLink   href="https://medium.com/@musk42022/floki-gold-gold-standard-of-memecoins-65b771133012" target="blank">
                <DataImage mw="30px" src={medium}/>
              </MenuLink>
            </div>
          </DrawerDivider>
        </DrawerBox>
      </Drawer>
    </MobileMainMenu>
  );
}

export default MobileMenu;

