import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import {
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import aboutImage from "../../../../assets/images/aboutImage.png";
import Paragraph from "../../Paragraph";
import Partner from "./partner";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
    padding: 40px 0 !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <GridWraper container>
            <GridItem container item xs={12}>
              <DataImage data-aos="zoom-in" mw="100%" src={aboutImage} />
              <SectionHeading data-aos="zoom-in" ta="center" ta599="center" fs="40px">
                Don't Miss Floki Gold!
              </SectionHeading>
              <Grid item xs={12} sx={10} md={9}>
                <Paragraph data-aos="fade-up" ta="center" ta599="center"  p="10px 0">
                  Inspired by the success of Bitcoin and Floki, one of the two
                  most successful coins and memecoins of all time, we named
                  ourselves after a what that truly embodies Satoshi’s vision
                  and memes to create the gold standard of memecoins!
                </Paragraph>
              </Grid>
            </GridItem>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
      <Partner />
    </>
  );
};

export default AboutSection;
