import React from "react";
import { Container, Grid } from "@mui/material";
import metamask from "../../../../assets/images/metamask.png";
import trustwallet from "../../../../assets/images/trustwallet.png";
import dextools from "../../../../assets/images/dextools.png";
import dexscrener from "../../../../assets/images/dexscrener.png";
import uniswap from "../../../../assets/images/uniswap.png";
import etherscan from "../../../../assets/images/etherscan.png";
import styled from "@emotion/styled";
import { DataImage, SectionHeading } from "../../../Styles/style";

const ImageGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 899px) {
    flex-direction: column;
  }
`;

const Div = styled.div`
   width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 899px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
  }
`

const ContinerWraper1 = styled(Container)`
  /* background: radial-gradient(circle at 50% 60%, #1177f37a, transparent 50%); */
  width: 100%;
  padding: 100px 0;
  @media (max-width: 599px){
    padding: 0;
  }
`;

const Partner = () => {
  return (
    <>
      <ContinerWraper1>
        <Grid container>
          <Grid item xs={12}>
            <SectionHeading ta="center" p="30px 0" fs="26px" data-aos="zoom-in">Gold Partners</SectionHeading>
          </Grid>
          <ImageGrid item xs={12} md={12}>
            <Div
              style={{ display:"flex", alignItems:"center",justifyContent: "space-between", width: "100%" }}
            >
              <DataImage mw="120px" src={metamask} data-aos="fade-up" data-aos-duration="1000"/>
              <DataImage mw="120px" src={trustwallet} data-aos="fade-up" data-aos-duration="1200"/>
              <DataImage mw="90px" src={dextools} data-aos="fade-up" data-aos-duration="1400"/>
              <DataImage mw="120px" src={dexscrener} data-aos="fade-up" data-aos-duration="1600"/>
              <DataImage mw="120px" src={uniswap} data-aos="fade-up" data-aos-duration="1800"/>
              <DataImage mw="120px" src={etherscan} data-aos="fade-up" data-aos-duration="2000"/>
            </Div>
          </ImageGrid>
        </Grid>
      </ContinerWraper1>
    </>
  );
};

export default Partner;
