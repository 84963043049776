import React from "react";
import logo from "../../../../assets/images/logo.png";
import { DesktopMainMenu, MenuLink } from "../styles";
import { DataImage, SectionHeading } from "../../../Styles/style";
import twitter from "../../../../assets/images/twitter.png";
import telegram from "../../../../assets/images/telegram.png";
import medium from "../../../../assets/images/medium.png";
const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
        <DataImage src={logo} mw="40px" alt="Logo" />
        <SectionHeading fs="18px">FLOKI GOLD</SectionHeading>
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
        <MenuLink p="0 5px 0 10px" href="https://twitter.com/Flokigold_erc" target="blank">
          <DataImage mw="30px" src={twitter} />
        </MenuLink>
        <MenuLink p="0 5px" href="https://t.me/Flokigold_erc" target="blank">
          <DataImage mw="30px" src={telegram} />
        </MenuLink>
        <MenuLink p="0 5px" href="https://medium.com/@musk42022/floki-gold-gold-standard-of-memecoins-65b771133012" target="blank">
          <DataImage mw="30px" src={medium} />
        </MenuLink>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
