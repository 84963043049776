import React from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { SectionHeading } from "../../../Styles/style";
import bg from "../../../../assets/images/bg.jpg";
import Paragraph from "../../Paragraph";
import LockerMenu from "../../LockerMenu";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 130px);
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    background-position: left;
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  height: calc(100vh - 80px);
  position: relative;
  padding: 50px 0px 0;
  display: flex;
  align-items: start;
  justify-content: start;
  @media (max-width: 599px) {
    padding: 30px 0px 0;
    height: calc(100vh - 80px);
    align-items: center;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: 599px) {
    align-items: start;
  }
`;
const Heading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "left")};
  font-size: ${(props) => (props.fs ? props.fs : "90px")};
  font-family: ${(props) => (props.fm ? props.fm : "PoppinsB")};
  line-height: 1.3;
  background-clip: text;
  background: linear-gradient(
    to right,
    #bb862a,
    #f0b706,
    #fef387,
    #f0b706,
    #eccb64,
    #bb862a
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 20px #f0b50657;
  text-transform: uppercase;
  @media (max-width: 599px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "50px")};
  }
`;
const ContractDiv = styled.div`
  width: 90%;
  border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 12px;
  padding: 8px 10px;
`;
const HeroSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="home">
      <LockerMenu />
      <InnerContinerWraper>
        <GridWraper container>
          <GridItem item xs={12} sm={10} md={6}>
            <Heading data-aos="zoom-in">FLOKI GOLD</Heading>
            <SectionHeading data-aos="zoom-in">The People's Cryptocurrency</SectionHeading>
            <Paragraph p="10px 0" data-aos="zoom-in">
              Floki Gold is designed to be the true gold standard of meme coins,
              made for the people, by the people.
            </Paragraph>
            <div data-aos="fade-up"
              style={{ display: "flex", columnGap: "10px", paddingTop: "10px" }}
            >
              <Link href="" target="blank">
                <CustomButton hbgc="transparent" hc="#fff" p="4px 30px">
                  Buy Now
                </CustomButton>
              </Link>
              <Link href="" target="blank">
                <CustomButton bgc="transparent" c="#fff" hc="#000" hbgc="#fff">
                  View Chart
                </CustomButton>
              </Link>
            </div>
            <ContractDiv data-aos="fade-up">
              <SectionHeading fs="16px" fs599="12px" fm="PoppinsB">
                Contract Address:
              </SectionHeading>
              <SectionHeading
                fs="16px"
                fs599="12px"
                fm="PoppinsB"
                style={{ wordBreak: "break-all", textAlign: "right" }}
              >
                0x000761523762354562183546523
              </SectionHeading>
            </ContractDiv>
          </GridItem>
          <GridItem item xs={12} sm={2}></GridItem>
        </GridWraper>
      </InnerContinerWraper>
    </ContinerWraper>
  );
};

export default HeroSection;
