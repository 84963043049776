import React, { useEffect } from "react";
import HeroSection from "../../Components/Sections/S1-Hero";
import gradient from "../../../assets/images/gradient.png";
import { AbsoluiteImage, PageWrapper } from "../../Styles/style";
import AboutSection from "../../Components/Sections/S2-About";
import TokenSection from "../../Components/Sections/S3-Tokenomics";
import MemesSection from "../../Components/Sections/S4-Memes";
import Footer from "../../Components/Sections/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import IFrame from "../../Components/Sections/IFrame";
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <AbsoluiteImage src={gradient} />
      <AbsoluiteImage t="2%" l="65%" src={gradient} />
      <AbsoluiteImage t="22%" l="-60%" src={gradient} />
      <AbsoluiteImage t="38%" l="65%" src={gradient} />
      <AbsoluiteImage t="56%" l="-60%" src={gradient} />
      <AbsoluiteImage t="73%" l="65%" src={gradient} />
      <HeroSection />
      <AboutSection />
      <TokenSection />
      <MemesSection />
      <IFrame />
      <Footer />
    </PageWrapper>
  );
};

export default MainPage;
