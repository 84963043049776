import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const PageWrapper = styled.div`
  background-color: #000;
  position: relative;
  overflow: hidden !important;
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "90%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
   @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
    
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "-15%")};
  left: ${(props) => (props.l ? props.l : "-50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(0%,0%)")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: 400px;
    top: ${(props) => (props.t ? props.t : "0%")};
    left: ${(props) => (props.l ? props.l : "0")};
    padding: ${(props) => (props.m ? props.m : "0 0px 0 0")};
    opacity: 0.2;
  }
  @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  }
`;
const SectionHeading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "left")};
  font-size: ${(props) => (props.fs ? props.fs : "36px")};
  font-family: ${(props) => (props.fm ? props.fm : "PoppinsSB")};
  color: #fff;
  @media (max-width: 599px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "22px")};
  }
`;
export { PageWrapper, DataImage, AbsoluiteImage, SectionHeading };
