import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 20px 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 50px 20px !important;
  }
`;

const IFrame = styled.iframe`
  border: 0;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  max-width: 100%;
  min-height: calc(100vh - 100px);
  box-shadow: 0px 0px 20px rgba(231, 175, 5, 0.434);
`;
const IFrameSec = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="uniswap">
        <InnerContinerWraper>
          <IFrame
            title="uniswap"
            src="https://app.uniswap.org/#/swap?theme=dark"
            height="100%"
            width="100%"
          />
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default IFrameSec;
