import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  padding: 0 !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  /* padding: 20px 0px 0; */
  display: flex;
  align-items: center;
  justify-content: start;
  @media (max-width: 599px) {
    padding: 50px 0px 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  row-gap: 10px;
  column-gap: 20px;
`;
const Heading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px 0px 5px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "left")};
  font-size: ${(props) => (props.fs ? props.fs : "16px")};
  font-family: ${(props) => (props.fm ? props.fm : "PoppinsB")};
  line-height: 1;
  background-clip: text;
  background: linear-gradient(
    to right,
    #bb862a,
    #f0b706,
    #fef387,
    #f0b706,
    #eccb64,
    #bb862a
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 20px #f0b50657;
  text-transform: uppercase;
  @media (max-width: 599px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "16px")};
  }
`;
const TokenGrid = styled(Grid)`
  width: 100%;
  background-color: #090909;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  padding: 40px 20px;
`;
const TokenSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <InnerContinerWraper>
          <GridWraper container>
            <GridItem item xs={12}>
              <SectionHeading data-aos="zoom-in" ta="center" fs="26px" p="0 0 20px">
                Tokenomics
              </SectionHeading>
            </GridItem>
            <GridItem
              container
              item
              xs={12}
              sx={{
                flexDirection: "row !important",
                alignItems: "center  !important",
                justifyContent: "center !important",
              }}
            >
              <TokenGrid item xs={12} md={9.4} data-aos="fade-right" data-aos-duration="1000">
                <Heading>Supply</Heading>
                <SectionHeading fm="PoppinsM">420,690,000,000,000</SectionHeading>
              </TokenGrid>
              <TokenGrid item xs={12} sm={5.7} md={3}  data-aos="fade-up" data-aos-duration="1000">
                <Heading>Taxes</Heading>
                <SectionHeading fm="PoppinsM">
                  0/2
                  <sup
                    style={{
                      fontSize: "16px",
                      fontFamily: "PoppinsL",
                      padding: "0 0 5px 5px ",
                    }}
                  >
                    Final*
                  </sup>
                </SectionHeading>
              </TokenGrid>
              <TokenGrid item xs={12} sm={5.7} md={3}  data-aos="fade-up" data-aos-duration="1300">
                <Heading>Lp Lock</Heading>
                <SectionHeading fm="PoppinsM">TBA</SectionHeading>
              </TokenGrid>
              <TokenGrid item xs={12} sm={12} md={3}  data-aos="fade-up" data-aos-duration="1600">
                <Heading>Renounced</Heading>
                <SectionHeading fm="PoppinsM">TBA</SectionHeading>
              </TokenGrid>
            </GridItem>
            <GridItem container item xs={12}>
              <SectionHeading ta="center" fs="22px" p="20px 0 0">
                More Details
              </SectionHeading>
              <Grid item xs={12} sx={10} md={9}>
                <Paragraph ta="center" ta599="center" p="10px 0">
                  🕵️ Stealth Launch 🕵🏾
                  <br />
                  🤖🙅🏽‍♀️ Anti-Bot
                  <br />
                  🔥 Liquidity Pool Tokens Burned 🔥
                  <br />
                  Max wallet size limited to 0.5%
                  <br />
                  Limit removed within 60 minute of launch
                  <br />
                  Starting tax: 15/45 allow tax to have option to raise it to
                  95%
                  <br />
                </Paragraph>
              </Grid>
            </GridItem>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default TokenSection;
