import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { DataImage } from "../../../Styles/style";
import meme1 from "../../../../assets/images/meme1.png";
import meme2 from "../../../../assets/images/meme2.png";
import meme3 from "../../../../assets/images/meme3.png";
import meme4 from "../../../../assets/images/meme4.png";
import meme5 from "../../../../assets/images/meme5.png";
import meme6 from "../../../../assets/images/meme6.png";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  padding: 0 !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 100px 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  /* padding: 20px 0px 0; */
  display: flex;
  align-items: start;
  justify-content: start;
  @media (max-width: 599px) {
    padding: 50px 0px 0;
  }
`;
const TokenGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 10px;
`;
const MemesSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="memes">
        <InnerContinerWraper>
            <GridWraper
              container
            >
              <TokenGrid item xs={12} sm={5.7} md={4}>
                <DataImage mw="90%"  mw599="100%" src={meme1} />
              </TokenGrid>
              <TokenGrid item xs={12} sm={5.7} md={4}>
                <DataImage mw="100%" src={meme2} />
              </TokenGrid>
              <TokenGrid item xs={12} sm={5.7} md={4}>
                <DataImage mw="51%" mw899="70%"  mw599="100%" src={meme3} />
              </TokenGrid>
              <TokenGrid item xs={12} sm={5.7} md={4}>
                <DataImage mw="100%" src={meme4} />
              </TokenGrid>
              <TokenGrid item xs={12} sm={5.7} md={4}>
                <DataImage mw="95%"   mw599="100%" src={meme5} />
              </TokenGrid>
              <TokenGrid item xs={12} sm={5.7} md={4}>
                <DataImage mw="100%" src={meme6} />
              </TokenGrid>
            </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default MemesSection;
