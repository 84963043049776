import React from "react";
import { Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import {
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import twitter from "../../../../assets/images/twitter.png";
import telegram from "../../../../assets/images/telegram.png";
import medium from "../../../../assets/images/medium.png";
import footer from "../../../../assets/images/footer.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding:0px 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 100px 0px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 599px) {
    padding: 70px 0px 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;

const ContractDiv = styled.div`
  width: 100%;
  border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  padding: 6px 10px;
`;

const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="footer">
        <InnerContinerWraper>
          <GridWraper container>
            <GridItem container item xs={12}>
              <DataImage  data-aos="zoom-in" mw="100px" src={footer} />
              <SectionHeading ta="center" data-aos="zoom-in">FLOKI GOLD</SectionHeading>
              <Grid item xs={12} sx={10} md={5}>
                <ContractDiv data-aos="fade-up">
                  <SectionHeading fs="14px" fs599="12px">
                    Contract Address:
                  </SectionHeading>
                  <SectionHeading
                    fs="14px"
                    fs599="12px"
                    style={{ wordBreak: "break-all", textAlign: "right" }}
                  >
                    0x000761523762354562183546523
                  </SectionHeading>
                </ContractDiv>
              </Grid>
              <Grid item xs={12} sx={10} md={9}>
                <Paragraph ta="center" ta599="center" p="30px 0"  data-aos="fade-up">
                  FLOKI GOLD is a meme coin with no intrinsic value or
                  expectation of financial return. There is no formal team or
                  roadmap. the coin is completely useless and for entertainment
                  purposes only.
                </Paragraph>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    columnGap: "5px",
                    paddingTop: "50px",
                  }}
                >
                  <Link href="https://twitter.com/Flokigold_erc" target="blank" >
                    <DataImage src={twitter}  data-aos="fade-right"/>
                  </Link>
                  <Link href="https://t.me/Flokigold_erc" target="blank"  data-aos="zoom-in">
                    <DataImage src={telegram} />
                  </Link>
                  <Link
                    href="https://medium.com/@musk42022/floki-gold-gold-standard-of-memecoins-65b771133012"
                    target="blank"
                  >
                    <DataImage src={medium}  data-aos="fade-left"/>
                  </Link>
                </div>
                <Paragraph ta="center" ta599="center" p="20px 0 50px"  data-aos="fade-up">
                  Copyright 2023 Floki Gold . All Rights Reserved Floki Gold
                </Paragraph>
              </Grid>
            </GridItem>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Footer;
